<template>
	<div>
		<div class="form">
			<van-form>
				<van-field label="个人资料">
					<template #input>
						<div class="van-cell__value van-field__value" @click="navEdit()">
							<div class="van-field__body">
								<input type="text" readonly="readonly" placeholder="修改资料" class="van-field__control" />
								<van-image class="arrow" :src="require('@/assets/arrow-right-gray.png')" fit="cover" />
							</div>
						</div>
					</template>
				</van-field>
				<van-field label="绑定手机">
					<template #input>
						<div class="van-cell__value van-field__value" @click="navBindMobile()">
							<div class="van-field__body">
								<input type="text" readonly="readonly" placeholder="已绑定" class="van-field__control" />
								<van-image class="arrow" :src="require('@/assets/arrow-right-gray.png')" fit="cover" />
							</div>
						</div>
					</template>
				</van-field>
				<van-field label="专业用户认证">
					<template #input>
						<div class="van-cell__value van-field__value" @click="navAuth()">
							<div class="van-field__body">
								<input type="text" readonly="readonly" :placeholder="userInfo.is_cert == 1 ? '已认证' : '去认证'" class="van-field__control" />
								<van-image class="arrow" :src="require('@/assets/arrow-right-gray.png')" fit="cover" />
							</div>
						</div>
					</template>
				</van-field>
			</van-form>
		</div>
		<div class="btn-view"><van-button block color="#ED0E0E" plain native-type="submit" @click="logout">退出登录</van-button></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			userInfo: {
				is_cert: '',
				mobile: ''
			}
		};
	},
	created() {
		console.log('created');
		this.getUserInfo();
		// 手机换绑成功
		this.$app.$on('bindSuccess', () => {
			console.log('手机换绑成功');
			this.getUserInfo();
		});
		// 认证成功
		this.$app.$on('authSuccess', () => {
			console.log('认证成功');
			this.getUserInfo();
		});
	},
	methods: {
		logout() {
			this.$store.dispatch('logout');
			window.location = '/';
		},
		async getUserInfo() {
			let { data } = await this.$service.post('user_info/detail');
			this.userInfo.is_cert = data.is_cert;
			this.userInfo.mobile = data.mobile;
		},
		navEdit() {
			this.$router.push({
				path: '/user/edit'
			});
		},
		navBindMobile() {
			this.$router.push({
				path: '/bind'
			});
		},
		navAuth() {
			this.$router.push({
				path: '/auth'
			});
		}
	}
};
</script>

<style scoped lang="less">
@import '~@/styles/form.less';
@import './status.less';
</style>
